import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Nav, Navbar, Container } from "react-bootstrap"
import Logo from "../images/logo.svg"
import * as styles from "../styles/navbar.module.scss"
import { HeroVideo } from "./HeroVideo"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export default function Header({ hiddenVideo }) {
  const [scrollActive, setScrollActive] = useState(false)

  useEffect(() => {
    const handler = () => {
      if (window.scrollY > 0) {
        setScrollActive(true)
      } else {
        setScrollActive(false)
      }
    }

    window.addEventListener("scroll", handler)
    return () => window.removeEventListener("scroll", handler)
  }, [])

  return hiddenVideo ? (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      className={styles.navbarContainer}
      style={{ backgroundColor: "black" }}
    >
      <Container fluid style={{ backgroundColor: "black" }}>
        <Navbar.Brand className={styles.logo} href="/">
          <img src={Logo} alt="logo torus cine" width={30} />
          <h1 className={styles.title}>
            torus <span className={styles.subtitle}>cine</span>
          </h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" variant="dark" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="justify-content-end align-items-center flex-grow-1 pe-3"
            style={{ backgroundColor: "black" }}
          >
            <AnchorLink className={styles.linksHeader} to="/#works">
              trabajos
            </AnchorLink>
            <Link className={styles.linksHeader} to="/about">
              nosotros
            </Link>
            <AnchorLink className={styles.linksHeader} to="/#contact">
              contacto
            </AnchorLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  ) : (
    <div className={styles.containerNavbarHero}>
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className={
          scrollActive
            ? [styles.navbarContainer, styles.activeScroll]
            : [styles.navbarContainer, styles.colorTransparent]
        }
      >
        <Container
          fluid
          className={scrollActive ? styles.colorBlack : styles.colorTransparent}
        >
          <Navbar.Brand className={styles.logo} href="/">
            <img src={Logo} alt="logo torus cine" />
            <h1 className={styles.title}>
              torus <span className={styles.subtitle}>cine</span>
            </h1>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            /* variant="dark" */ style={{ backgroundColor: "transparent" }}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              className={
                scrollActive
                  ? `justify-content-end align-items-center flex-grow-1 pe-3 ${styles.colorBlack}`
                  : `justify-content-end align-items-center flex-grow-1 pe-3 ${styles.colorTransparent}`
              }
            >
              <AnchorLink className={styles.linksHeader} to="/#works">
                trabajos
              </AnchorLink>
              <Link className={styles.linksHeader} to="/about">
                nosotros
              </Link>
              <AnchorLink className={styles.linksHeader} to="/#contact">
                contacto
              </AnchorLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <HeroVideo />
    </div>
  )
}
