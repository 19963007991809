import React from "react"
import Instagram from "../images/instagram.svg"
import Youtube from "../images/youtube.svg"
import Vimeo from "../images/vimeo.svg"
import * as styles from "../styles/footer.module.scss"
import { Fade } from "react-reveal"

export default function Footer() {
  return (
    <footer>
      <Fade bottom cascade>
        <div className={styles.containerIcons}>
          <a
            href="https://www.instagram.com/toruscine/"
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={Instagram} alt="instagram icon" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCxM6UhR97uH48j_bPrY47CA"
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={Youtube} alt="youtube icon" />
          </a>
          <a
            href="https://vimeo.com/user163991418"
            target={"_blank"}
            rel="noreferrer"
          >
            <img src={Vimeo} alt="vimeo icon" />
          </a>
        </div>
      </Fade>
      <p>Copyright 2021 Torus Cine</p>
    </footer>
  )
}
