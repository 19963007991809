import React, { useEffect, useState } from "react"
import volumeOn from "../images/volumen-on.svg"
import volumeOff from "../images/volumen-off.svg"
import * as styles from "../styles/hero.module.scss"
import { Fade } from "react-reveal"
import "animate.css"

export const HeroVideo = () => {
  const [muteSound, setMuteSound] = useState(true)
  const [videoUrl, setVideoUrl] = useState("")

  const handleMuted = () => {
    setMuteSound(prev => !prev)
  }

  useEffect(() => {
    if (window.matchMedia("(max-width: 575px)").matches) {
      setVideoUrl("https://res.cloudinary.com/deftnl7yb/video/upload/v1710800702/torus/torus-video-mobile.mp4")
    } else {
      setVideoUrl("https://res.cloudinary.com/deftnl7yb/video/upload/f_auto:video,q_auto/v1/torus/torus-wide-06032024_jjeb6k")
    }
  }, [])

  if (!videoUrl) return null

  return (
    <>
      <video
        className={styles.videoCustom}
        playsInline
        muted={muteSound}
        loop
        preload="auto"
        autoPlay
        disablePictureInPicture
        controlsList="nodownload"
      >
        <source
          src={videoUrl}
          type="video/mp4"
        />
      </video>
      <div
        className={
          muteSound
            ? "animate__animated animate__flash animate__infinite animate__slow"
            : ""
        }
      >
        <Fade right>
          {muteSound ? (
            <img
              src={volumeOff}
              onClick={handleMuted}
              className={styles.imageVolumen}
              alt="icono volumen off"
            />
          ) : (
            <img
              src={volumeOn}
              onClick={handleMuted}
              className={styles.imageVolumen}
              alt="icono volumen on"
            />
          )}
        </Fade>
      </div>
    </>
  )
}
