import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"

export default function SEO({
  title,
  description,
  image,
  siteUrl,
  lang = "en",
}) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          url
          title
          description
          image
          keywords
          author
          siteUrl
        }
      }
    }
  `)

  const defaults = data.site.siteMetadata

  const seo = {
    title: defaults.title,
    description: defaults.description,
    image: defaults.image,
    keywords: defaults.keywords,
    author: defaults.author,
    siteUrl: defaults.siteUrl,
  }

  const canonical = siteUrl || seo.siteUrl
  const metaDescription = description || seo.description
  const metaImage = image || seo.image

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      titleTemplate={`%s | ${seo.title}`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
      meta={[
        {
          name: "keywords",
          content: seo.keywords.join(","),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:creator`,
          content: seo.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    >
      <title>{title || seo.title}</title>
      <meta name="description" content={metaDescription} />
      <meta name="robots" content="noodp,noydir" />
      <meta
        name="google-site-verification"
        content="FB1oIbo98CaQdrgYLvwImdehHf6ng04eQtROqvbM_Zc"
      />
      <meta name="keywords" content={seo.keywords.join(",")} />
      <meta name={`og:title`} content={title} />
      <meta name={`og:description`} content={metaDescription} />
      <meta name={`og:type`} content={`website`} />
      <meta name={`og:image`} content={metaImage} />
      <meta name={`twitter:creator`} content={seo.author} />
      <meta name={`twitter:title`} content={title} />
      <meta name={`twitter:description`} content={metaDescription} />
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": "https://www.toruscine.com.ar",
          "name": "Torus Cine",
          "datePublished": "2022-02-01",
          "description": "Film production company based in Buenos Aires, Argentina."
        }
      `}
      </script>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'G-DJ8SFWM25M');`,
        }}
      />
    </Helmet>
  )
}
